.marker {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2em;
  height: 2em;
  user-select: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
.marker:hover {
  z-index: 1;
}
